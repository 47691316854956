<template>
  <VueFinalModal class="modal">
    <span>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.9881 29.2381L22.2381 37.9881C22.0755 38.1507 21.8825 38.2797 21.6701 38.3677C21.4576 38.4557 21.2299 38.501 21 38.501C20.7701 38.501 20.5424 38.4557 20.3299 38.3677C20.1175 38.2797 19.9245 38.1507 19.7619 37.9881C19.5993 37.8255 19.4703 37.6325 19.3823 37.4201C19.2943 37.2076 19.249 36.9799 19.249 36.75C19.249 36.5201 19.2943 36.2924 19.3823 36.0799C19.4703 35.8675 19.5993 35.6745 19.7619 35.5119L25.5259 29.75H5.25C4.78587 29.75 4.34075 29.5656 4.01256 29.2374C3.68437 28.9092 3.5 28.4641 3.5 28C3.5 27.5359 3.68437 27.0908 4.01256 26.7626C4.34075 26.4344 4.78587 26.25 5.25 26.25H25.5259L19.7619 20.4881C19.4335 20.1598 19.249 19.7144 19.249 19.25C19.249 18.7856 19.4335 18.3402 19.7619 18.0119C20.0902 17.6835 20.5356 17.499 21 17.499C21.4644 17.499 21.9098 17.6835 22.2381 18.0119L30.9881 26.7619C31.1508 26.9244 31.2799 27.1174 31.368 27.3299C31.456 27.5423 31.5014 27.77 31.5014 28C31.5014 28.23 31.456 28.4577 31.368 28.6701C31.2799 28.8826 31.1508 29.0756 30.9881 29.2381ZM42 7H29.75C29.2859 7 28.8408 7.18437 28.5126 7.51256C28.1844 7.84075 28 8.28587 28 8.75C28 9.21413 28.1844 9.65925 28.5126 9.98744C28.8408 10.3156 29.2859 10.5 29.75 10.5H42V45.5H29.75C29.2859 45.5 28.8408 45.6844 28.5126 46.0126C28.1844 46.3408 28 46.7859 28 47.25C28 47.7141 28.1844 48.1592 28.5126 48.4874C28.8408 48.8156 29.2859 49 29.75 49H42C42.9283 49 43.8185 48.6313 44.4749 47.9749C45.1313 47.3185 45.5 46.4283 45.5 45.5V10.5C45.5 9.57174 45.1313 8.6815 44.4749 8.02513C43.8185 7.36875 42.9283 7 42 7Z"
          fill="#e50038"
        />
      </svg>
    </span>
    <h1>Are you sure you want to log out?</h1>

    <div class="btn-group">
      <button @click="close" class="btn btn-tertiary">Cancel</button>

      <button @click="logOut" class="btn btn-primary">Log out</button>
    </div>
  </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from "vue-final-modal";
import { useAuthStore } from "@/stores/auth.tsx";
import { useUserStore } from "@/stores/userStore.tsx";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const userStore = useUserStore();

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};

const router = useRouter();

const logOut = () => {
  authStore.logout();
  userStore.logout();
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/modal.scss";

svg path {
  fill: #e50038;
}
</style>
<style>
.vue-modal {
  z-index: 1001 !important;
}
</style>
