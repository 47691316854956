<template>
  <div :class="['search-bar', { active: searchBarExpanded && isSmallScreen }]">
    <a class="mobile-search">
      <SvgHandler :icon="caretLeft" @click="hideSearchBar" />
    </a>

    <input
      type="text"
      ref="searchInput"
      v-model="searchQuery"
      placeholder="Search..."
      :class="['search-input', { focused: isFocused }]"
      @keydown.enter.prevent="onEnter"
      @keydown.down.prevent="highlightNextSuggestion"
      @keydown.up.prevent="highlightPreviousSuggestion"
      @keydown.esc.prevent="onEscKey"
      @keydown="handleKey"
      @focusin="onFocus"
      @focusout="onBlur"
    />
    <button @click="clearSearchQuery" class="x" v-if="searchQuery && !loading">
      <SvgHandler :icon="xIcon" />
    </button>

    <button
      @click="handleSearchButtonClick"
      class="search"
      :class="{ 'pr-3': isCreateRoute }"
      :disabled="loading && searchBarExpanded"
    >
      <SvgHandler :icon="searchIcon" />
      <span
        class="absolute animate-spin h-5 w-5 loading-indicator"
        v-if="loading && searchBarExpanded"
      >
        <SvgHandler :icon="loadingIcon" />
      </span>
    </button>

    <span class="history" v-if="searchHistoryStore.searchHistory.length > 0">
      <ul>
        <li
          v-for="(item, index) in searchHistoryStore.searchHistory"
          :key="index"
          class="search-result"
        >
          <span>
            <SvgHandler :icon="searchIcon" />
          </span>
          <span @click="selectSearch({ name: item.term })">
            {{ item.term }}
          </span>
          <span @click="removeSearchTerm(item.term)">
            <SvgHandler :icon="xIcon" />
          </span>
        </li>
      </ul>
    </span>

    <span
      v-if="searchSuggestions.length > 0 && searchBarExpanded"
      class="results"
    >
      <ul>
        <li
          v-for="(searchSuggestion, index) in searchSuggestions"
          :key="index"
          @click="selectSearch({ name: searchSuggestion })"
          :class="[
            'search-result',
            {
              highlighted:
                index === highlightedSuggestionIndex &&
                highlightedSuggestionIndex !== -1,
            },
          ]"
        >
          <span>
            <SvgHandler :icon="searchIcon" />
          </span>
          <span :key="searchSuggestion">
            {{ searchSuggestion }}
          </span>
          <span>
            <SvgHandler :icon="xIcon" />
          </span>
        </li>
      </ul>
    </span>

    <span
      class="results-cover"
      @click="
        () => {
          hideSearchBar();
          hideHistory();
        }
      "
    ></span>
  </div>

  <div class="search-state-empty">
    <div class="empty-state-placeholder">
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.2078 27.2928L22.949 21.0353C24.7631 18.8574 25.6676 16.064 25.4746 13.2362C25.2815 10.4084 24.0057 7.76385 21.9125 5.85275C19.8193 3.94164 17.0698 2.9111 14.2362 2.9755C11.4025 3.0399 8.70274 4.19429 6.69851 6.19851C4.69429 8.20274 3.5399 10.9025 3.4755 13.7362C3.4111 16.5698 4.44164 19.3193 6.35275 21.4125C8.26385 23.5057 10.9084 24.7815 13.7362 24.9746C16.564 25.1676 19.3574 24.2631 21.5353 22.449L27.7928 28.7078C27.8857 28.8007 27.996 28.8744 28.1174 28.9247C28.2388 28.975 28.3689 29.0008 28.5003 29.0008C28.6317 29.0008 28.7618 28.975 28.8832 28.9247C29.0046 28.8744 29.1149 28.8007 29.2078 28.7078C29.3007 28.6149 29.3744 28.5046 29.4247 28.3832C29.475 28.2618 29.5008 28.1317 29.5008 28.0003C29.5008 27.8689 29.475 27.7388 29.4247 27.6174C29.3744 27.496 29.3007 27.3857 29.2078 27.2928ZM5.50029 14.0003C5.50029 12.2203 6.02813 10.4802 7.01706 9.00015C8.00599 7.52011 9.4116 6.36656 11.0561 5.68537C12.7007 5.00418 14.5103 4.82595 16.2561 5.17322C18.0019 5.52048 19.6056 6.37765 20.8642 7.63632C22.1229 8.895 22.9801 10.4986 23.3274 12.2445C23.6746 13.9903 23.4964 15.7999 22.8152 17.4444C22.134 19.089 20.9805 20.4946 19.5004 21.4835C18.0204 22.4724 16.2803 23.0003 14.5003 23.0003C12.1141 22.9976 9.8265 22.0486 8.13925 20.3613C6.45199 18.6741 5.50293 16.3864 5.50029 14.0003Z"
          fill="#737373"
        />
      </svg>

      <h1>Search for videos & channels</h1>
      <p>
        Search by title, topics and channels by typing in your search results
        below!
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from "vue";
import caretLeft from "@/assets/playtv/icons/caret-left.svg";
import searchIcon from "@/assets/playtv/icons/search_icon.svg";
import xIcon from "@/assets/playtv/icons/x.svg";
import SvgHandler from "../svgHandler/SvgHandler.vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import { useRouter, useRoute } from "vue-router";
import { debounce } from "lodash";
import loadingIcon from "@/assets/playtv/icons/loading.svg";
import { useSearchHistoryStore } from "@/stores/searchHistoryStore";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

const searchBarExpanded = ref(false);
const searchQuery = ref("");
const loading = ref(false);
const searchSuggestions = ref([]);
const suggestedSearchLimit = 25;
const searchInput = ref(null);
const highlightedSuggestionIndex = ref(-1);
const router = useRouter();
const route = useRoute();
const previousSearchQuery = ref("");
const isSmallScreen = ref(window.innerWidth <= 480);
const searchHistoryStore = useSearchHistoryStore();
const isFocused = ref(false);
const $toast = useToast();

const isCreateRoute = computed(() => {
  return (
    route.path.startsWith("/create-video") ||
    route.path.startsWith("/create-burst")
  );
});

const handleKey = (e) => {
  if (e.code === "Enter" || e.code === "ArrowUp" || e.code === "ArrowDown") {
    return;
  }
  searchBarExpanded.value = true;
};

const removeSearchTerm = (term) => {
  console.log("removing", term);
  searchHistoryStore.removeSearchTerm(term);
};

const debouncedOnEnter = debounce(() => {
  if (searchQuery.value.length < 2) {
    searchSuggestions.value = [];
    return;
  }

  previousSearchQuery.value = searchQuery.value;
  fetchResults();
}, 500);

watch(searchQuery, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    debouncedOnEnter();
  }
});

const onFocus = () => {
  searchBarExpanded.value = true;

  isFocused.value = true;
};

const onBlur = () => {
  searchSuggestions.value = [];
};

const hideSearchBar = () => {
  searchBarExpanded.value = false;
};

const hideHistory = () => {
  isFocused.value = false;
};

const selectSearch = (e) => {
  // clearSearchQuery();
  searchQuery.value = e.name;
  hideSearchBar();
  router.push(`/search?query=${e.name}`);
};

const clearSearchQuery = () => {
  searchQuery.value = "";
};

const handleSearchButtonClick = () => {
  if (!searchBarExpanded.value) {
    searchBarExpanded.value = true;
    if (searchInput.value) {
      searchInput.value.focus();
    }
    return;
  }

  if (!isMinLength()) {
    return;
  }

  if (searchQuery.value !== "") {
    hideSearchBar();
    router.push(`/search?query=${searchQuery.value}`);
    searchBarExpanded.value = false;
    return;
  }

  hideSearchBar();
};

const isMinLength = () => {
  if (searchQuery.value.trim().length < 2) {
    $toast.info("Search query must be at least 2 characters long", {
      position: "top",
    });

    return false;
  }

  return true;
};

const onEnter = () => {
  if (!isMinLength()) {
    return;
  }

  if (
    highlightedSuggestionIndex.value !== -1 &&
    highlightedSuggestionIndex.value < searchSuggestions.value.length
  ) {
    searchQuery.value =
      searchSuggestions.value[highlightedSuggestionIndex.value];
    highlightedSuggestionIndex.value = -1;
  }

  if (searchQuery.value.length < 2) {
    return;
  }

  searchHistoryStore.addSearchTerm(searchQuery.value);

  router.push(`/search?query=${searchQuery.value}`);
  hideSearchBar();
};

const highlightNextSuggestion = () => {
  if (highlightedSuggestionIndex.value < searchSuggestions.value.length - 1) {
    highlightedSuggestionIndex.value++;
  } else {
    highlightedSuggestionIndex.value = -1;
  }
};

const highlightPreviousSuggestion = () => {
  if (searchSuggestions.value.length === 0) return;

  if (highlightedSuggestionIndex.value > 0) {
    highlightedSuggestionIndex.value--;
  } else {
    highlightedSuggestionIndex.value = searchSuggestions.value.length - 1;
  }
};

const onEscKey = () => {
  clearSearchQuery();
  hideSearchBar();
};

const fetchResults = async () => {
  try {
    // searchSuggestions.value = [];
    loading.value = true;

    const q = searchQuery.value;

    const { data, status } = await apiBackendAuthAxios.post(
      "/search/autocomplete/suggestions",
      {
        q,
        postType: "VIDEO",
      }
    );

    if (searchQuery.value.trim().toLowerCase() !== q.trim().toLowerCase()) {
      return;
    }

    if (status === 204 || !data) {
      searchSuggestions.value = [];
      highlightedSuggestionIndex.value = -1;
      return;
    }

    searchSuggestions.value = data.data
      .map((t) => t.name)
      .slice(0, suggestedSearchLimit);

    if (searchSuggestions.value.length > 0) {
      highlightedSuggestionIndex.value = -1;
    } else {
      highlightedSuggestionIndex.value = -1;
    }
  } catch (e) {
    console.error(e);
    // TODO show error message
  } finally {
    //
    loading.value = false;
  }
};

const updateScreenSize = () => {
  isSmallScreen.value = window.innerWidth <= 480;
};

onMounted(() => {
  window.addEventListener("resize", updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateScreenSize);
});

watch(isSmallScreen, (newVal) => {
  if (!newVal) {
    searchBarExpanded.value = false;
  }
});
</script>
<style scoped lang="scss">
@import "../../assets/styles/mixins.scss";

@mixin searchButtonFull {
  width: 42px;
  top: initial;
  position: initial;
  background-color: var(--navbar-background);
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-width: 1px 1px 1px 0px;
  border-color: var(--input-border-color);
  border-style: solid;
  padding-left: 0.5em;
  padding-right: 0.75em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.mobile-search {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.search-bar {
  position: relative;

  @include breakpoint(small) {
    max-width: 500px;
    width: 100%;

    //

    input {
      width: 84%;
    }
  }

  @include breakpoint(medium) {
    max-width: 500px;
  }

  input {
    flex-grow: 1;
    background-color: var(--background-color);
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-width: 1px 0 1px 1px;
    border-color: var(--input-border-color);
    border-style: solid;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: inherit;
    z-index: var(--z-index-header-menu);
    color: var(--primary);

    @include breakpoint(small) {
      display: inline-block;
      top: -4px;
      position: relative;
    }

    &:focus {
      height: 32px;

      ~ button.search {
        left: 1px;
        margin-left: 1px;
        width: 41px;

        @include breakpoint(small) {
          margin-left: 0;
        }

        .svg-handler {
          position: relative;
          left: -1px;
        }
      }
    }
  }

  button.x {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 74px;
    z-index: var(--z-index-header);

    .svg-handler {
      zoom: 0.8;
    }

    // @include breakpoint(small) {
    //   display: inline-block;
    //   top: 10px;
    //   right: 30px;
    // }

    @include breakpoint(small) {
      display: inline-block;
      right: initial;
      margin-left: -26px;
      top: 10px;
    }
  }

  button.search {
    z-index: 1000;
    &[disabled] {
      .svg-handler {
        opacity: 0.1;
      }
    }

    @include breakpoint(small) {
      @include searchButtonFull;
    }
  }

  input,
  a,
  button.x {
    display: none;
  }

  button.search {
    top: 3px;
    position: relative;
  }

  @include breakpoint(small) {
    input,
    button.x {
      display: inline-block;
    }
  }

  &.active {
    position: absolute;
    width: 100%;
    background-color: var(--navbar-background);
    display: flex;
    z-index: 1;
    align-items: center;
    font-size: 16px;
    padding: 0 1.75em 0 1em;

    @media (max-width: 768px) {
      left: 0;
    }

    @include breakpoint(small) {
      position: relative;
      padding: 0;
      left: initial;
      margin: 0;

      input {
        top: initial;
      }
    }

    a {
      display: inline-block;
      cursor: pointer;
      min-width: 40px;
      text-align: center;
      margin-right: 0.25em;

      > span {
        display: inline-flex;
        margin-top: 6px;
      }
    }

    input {
      display: inline-block;
    }

    button.search {
      @include searchButtonFull;
    }

    button.x {
      display: inline-block;
    }
  }
}

.search-button-state-full {
  top: initial;
  position: initial;
  background-color: var(--navbar-background);
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-width: 1px 1px 1px 0px;
  border-color: var(--input-border-color);
  border-style: solid;
  padding-left: 0.5em;
  padding-right: 0.75em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.history,
.results {
  position: absolute;
  z-index: 1003;
  border: none;
  overflow-y: auto;
  z-index: 1000;
  height: calc(100svh - var(--navbar-height) - 7px);
  width: 100%;
  box-shadow: 0px 0px 2px var(--background-color-transparent-reverse);
  border-radius: var(--radius);
  left: 0;
  top: calc(var(--navbar-height) - 7px);
  color: var(--primary);
  width: calc(100vw);
  background-color: var(--background-color);

  @include breakpoint(small) {
    position: fixed;
    width: calc(100% - 10em);
    left: 5em;
    top: 48px;
    height: auto;
    max-height: 50vh;
  }

  @include breakpoint(medium) {
    position: absolute;
    top: 42px;
    left: 0;
    max-width: 420px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      cursor: pointer;

      &.highlighted {
        background-color: var(--background-secondary-color);
      }

      span:nth-child(3) {
        //
        // TEMPORARILY HIDDEN
        //
        visibility: hidden;
      }
    }
  }

  + .history + .results-cover {
    display: block;
  }

  .search-result {
    display: flex;
    gap: 0.75em;
    align-items: center;
    line-height: 1.8em;

    :nth-child(1) {
      .svg-handler {
        zoom: 0.6;
      }
    }

    :nth-child(2) {
      flex-grow: 2;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :nth-child(3) {
      .svg-handler {
        zoom: 0.6;
      }
    }
  }
}

.history {
  display: none;

  ul li span:nth-child(3) {
    visibility: visible;
  }
}

input.search-input {
  @include breakpoint(small) {
    &.focused:placeholder-shown {
      + button + .history {
        display: block;

        + .results-cover {
          display: block;
        }
      }
    }
  }
}

.results-cover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 999;
}

.loading-indicator {
  top: 6px;
  right: 41px;

  @include breakpoint(small) {
    right: 14px;
    top: 4px;
  }
}

.search-bar.active {
  ~ .search-state-empty {
    display: inline-block;

    @include breakpoint(small) {
      display: none;
    }
  }

  .mobile-search {
    // display: none;
  }
}

.search-state-empty {
  display: none;
  background: var(--background-color);
  position: absolute;
  height: calc(100vh - var(--navbar-height));
  width: 100vw;
  top: var(--navbar-height);
  left: 0;
}

.empty-state-placeholder {
  margin-top: 4.25em;
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75em;

  h1,
  p {
    text-align: center;
  }
}
</style>
