<template>
  <div
    class="burst-grid mt- w-full"
    :class="!loading ? 'overflow-x-auto' : 'overflow-x-hidden mb-8'"
    @scroll="handleScroll"
  >
    <template v-if="loading">
      <BurstSkeleton v-for="n in numberOfBursts" :key="n" />
    </template>
    <template v-else>
      <div v-for="video in bursts" :key="video.id" class="videoContainer">
        <div class="w-full">
          <VideoPreview :video="video" />
        </div>
      </div>
      <div
        v-if="loadingMore"
        class="p-8 flex items-center justify-center h-full"
      >
        <span class="animate-spin size-10">
          <SvgHandler :icon="loadingIcon" />
        </span>
      </div>
      <div
        v-else-if="!loading && bursts.length > 0 && endOfBursts"
        class="flex items-center justify-center h-full"
      >
        <span class="text-center"> No more bursts </span>
      </div>
    </template>
  </div>
</template>

<script setup>
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import BurstSkeleton from "@/components/BurstSkeleton.vue";
import SvgHandler from "@/components/svgHandler/SvgHandler.vue";
import loadingIcon from "@/assets/playtv/icons/loading.svg";

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  loadingMore: {
    type: Boolean,
    required: false,
    default: false,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
  nextCursor: {
    type: String,
    required: false,
    default: null,
  },
  numberOfBursts: {
    type: Number,
    required: false,
    default: 20,
  },
  bursts: {
    type: Array,
    required: true,
  },
  endOfBursts: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["load-more-bursts"]);

const handleScroll = (e) => {
  const { target } = e;

  if (
    target.scrollLeft + target.clientWidth >= target.scrollWidth - 10 &&
    !props.loadingMore &&
    !props.loading &&
    !props.endOfBursts
  ) {
    emit("load-more-bursts");
  }
};
</script>
<style scoped>
.burst-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(
    150px,
    1fr
  ); /* Each video preview will have a minimum width of 150px */
  gap: 0.5rem; /* Adds spacing between video previews */
  align-items: start; /* Centers the videos vertically */
  padding-bottom: 1rem; /* Adds some padding for better visibility */
}

.burst-grid.overflow-x-hidden {
  white-space: nowrap; /* Prevents content wrapping during loading */
}

/* Constrains the size of each video preview */
.videoContainer {
  max-height: 90vh; /* Ensures video previews don't exceed 90% of the viewport height */
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.videoContainer video {
  height: 100%;
  max-width: 100%;
  object-fit: cover; /* Ensure video fits within the container */
}

/* Optional: Hide scrollbar in WebKit-based browsers */
.burst-grid::-webkit-scrollbar {
  display: none;
}
</style>
