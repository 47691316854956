import { defineStore } from "pinia";
import { useVideoStore } from "./videoStore.tsx";

// @ts-ignore
export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      accessToken: null,
      refreshToken: null,
      authenticated: false,
      isUpdatingAccessToken: false,
      darkMode: null,
    };
  },
  actions: {
    logout() {
      const videoStore = useVideoStore();
      videoStore.sendBatchedVideoViews();
      videoStore.clearWatchedVideos();

      this.accessToken = null;
      this.refreshToken = null;
      this.authenticated = false;
    },
    logIn({ refreshToken, accessToken }) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.authenticated = true;
    },
    setUpdatingAccessToken(value) {
      this.isUpdatingAccessToken = value;
    },
    setDarkMode(darkMode) {
      this.darkMode = darkMode;
    },
  },
  persist: true,
  share: {
    enable: true,
    initialize: true,
  },
});
